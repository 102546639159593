const stores = {
  R409: "銅鑼灣",
  R428: "IFC",
  R499: "廣東道",
  R673: "APM",
  R485: "又一城",
  R610: "新城市",
};

export default { stores };
