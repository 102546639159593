import React, { useContext, useEffect, useState } from "react";
import AppleStores from "../utils/AppleStores";
import ProductModels from "../utils/ProductModels";
import LocalStorageController from "../utils/LocalStorageController";
import { SavedCounter } from "../utils/SavedCounterContext";

function Header() {
  const { countValue, setCountValue } = useContext(SavedCounter);
  const [modifyBtn, setModifyBtn] = useState(false);
  const [mobileMode, setMobileMode] = useState(false);

  const tempModelList = LocalStorageController.savedModels;
  const tempStoreList = LocalStorageController.savedAppleStore;

  const delAllModelsBtnClicked = () => {
    tempModelList.map((model) => {
      document.getElementById(model).checked = false;
    });
    tempModelList.splice(0, tempModelList.length);
    window.localStorage.setItem("savedModels", JSON.stringify(tempModelList));
    const updatedCountValue = {
      ...countValue, // Copy the existing countValue
      headerModify: false, // Update the headerModify property
    };
    setCountValue(updatedCountValue);
    window.location.reload();
  };

  const modelsOnClick = (e) => {
    let model = e.target.value;
    if (tempModelList.indexOf(model) === -1) {
      tempModelList.push(model);
      document.getElementById(model).checked = true;
    } else {
      tempModelList.splice(tempModelList.indexOf(e.target.value), 1);
      document.getElementById(model).checked = false;
    }
    setModifyBtn(true);
    const updatedCountValue = {
      ...countValue, // Copy the existing countValue
      headerModify: true, // Update the headerModify property
    };
    setCountValue(updatedCountValue);
  };

  const storesOnClick = (e) => {
    let store = e.target.value;
    if (tempStoreList.indexOf(store) === -1) {
      // if the model is not saved, add it to the list
      tempStoreList.push(store);
      document.getElementById(store).checked = true;
    } else {
      // if the model is saved, remove it.
      tempStoreList.splice(tempStoreList.indexOf(e.target.value), 1);
      document.getElementById(store).checked = false;
    }
    setModifyBtn(true);
    const updatedCountValue = {
      ...countValue, // Copy the existing countValue
      headerModify: true, // Update the headerModify property
    };
    setCountValue(updatedCountValue);
  };

  const modifyBtnClick = () => {
    window.localStorage.setItem("savedModels", JSON.stringify(tempModelList));
    window.localStorage.setItem(
      "savedAppleStore",
      JSON.stringify(tempStoreList)
    );
    const updatedCountValue = {
      ...countValue, // Copy the existing countValue
      headerModify: false, // Update the headerModify property
    };
    setCountValue(updatedCountValue);
    window.location.reload();
  };

  const displayModeBtnClicked = () => {
    if (!mobileMode) {
      //LocalStorageController.storage.setItem("mobileMode", true);
      const updatedCountValue = {
        ...countValue, // Copy the existing countValue
        mobileMode: true,
      };
      setCountValue(updatedCountValue);
      setMobileMode(true);
    } else {
      //LocalStorageController.storage.setItem("mobileMode", false);
      const updatedCountValue = {
        ...countValue, // Copy the existing countValue
        mobileMode: false,
      };
      setCountValue(updatedCountValue);
      setMobileMode(false);
    }
  };

  useEffect(() => {
    // first find localstorage savedModels, if exist then do checked to checkbox
    LocalStorageController.savedModels.forEach((model) => {
      document.getElementById(model).checked = true;
    });

    // first find localstorage savedAppleStore, if exist then do checked to checkbox
    LocalStorageController.savedAppleStore.forEach((store) => {
      document.getElementById(store).checked = true;
    });

    // setMobileMode(LocalStorageController.mobileMode);
    var mobile = require("is-mobile");
    setMobileMode(mobile());
  }, []);

  return (
    <div className="header">
      {/* Confirm Delete All Dialog */}
      <dialog id="confirm_delAll_dialog" className="modal z-50">
        <div className="modal-box model-box-warning">
          <h3 className="font-bold text-lg">刪除所有型號</h3>
          <p className="py-4">確定刪除所有型號？動作不能復原。</p>
          <div className="modal-action">
            <button className="btn btn-error" onClick={delAllModelsBtnClicked}>
              確定
            </button>
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn btn-secondary">取消</button>
            </form>
          </div>
        </div>
      </dialog>

      {/* More then 20 Products Dialog */}
      <dialog id="product_overflow_dialog" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">注意！</h3>
          <p className="py-4">
            由於蘋果API只支援一次性查詢20個貨品，下面表格只顯示頭20項追蹤貨品，請減少追蹤數量至最多20項。
          </p>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>

      {/* Main UI */}
      <div className="navbar bg-neutral text-neutral-content shadow">
        <div className="flex pr-5">
          <button className="btn btn-ghost normal-case text-xl font-bold text-secondary no-animation">
            有得賣未?
          </button>
        </div>
        <div className="navbar-start">
          {/* Product List */}
          <div className="join justify-center items-center">
            <div className="dropdown dropdown-end z-30">
              <ul className="menu menu-horizontal rounded-lg">
                <li>
                  <details>
                    <summary>產品</summary>
                    <ul className="overflow-auto rounded-xl w-max bg-zinc-800 lg:flex lg:flex-row ">
                      <li>
                        <details open>
                          <summary>工具</summary>
                          <ul className="w-full pr-3">
                            <li className="py-1">
                              <button
                                className="btn btn-warning btn-sm"
                                onClick={() =>
                                  document
                                    .getElementById("confirm_delAll_dialog")
                                    .show()
                                }
                              >
                                刪除所有型號
                              </button>
                            </li>
                            <li className="py-1">
                              <a>
                                產品數量(最多20):
                                <br />
                                {tempModelList.length} /20
                              </a>
                            </li>
                          </ul>
                        </details>
                      </li>
                      {Object.entries(ProductModels.Products).map(
                        (ProductCat, iPhones) => (
                          <li>
                            {mobileMode ? (
                              <details>
                                <summary>{ProductCat[0]}</summary>
                                <ul className="w-full">
                                  {Object.entries(
                                    ProductModels.Products[ProductCat[0]]
                                  ).map((Product) => (
                                    <li className="py-1">
                                      <button
                                        className="btn btn-ghost btn-sm flex justify-start"
                                        value={Product[0]}
                                        onClick={modelsOnClick}
                                      >
                                        <input
                                          type="checkbox"
                                          class="checkbox checkbox-primary checkbox-xs p-0 gap-1"
                                          id={Product[0]}
                                          value={Product[0]}
                                          onChange={modelsOnClick}
                                        />
                                        {Product[1]}
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                              </details>
                            ) : (
                              <details open>
                                <summary>{ProductCat[0]}</summary>
                                <ul className="w-full">
                                  {Object.entries(
                                    ProductModels.Products[ProductCat[0]]
                                  ).map((Product) => (
                                    <li className="py-1">
                                      <button
                                        className="btn btn-ghost btn-sm flex justify-start"
                                        value={Product[0]}
                                        onClick={modelsOnClick}
                                      >
                                        <input
                                          type="checkbox"
                                          class="checkbox checkbox-primary checkbox-xs p-0 gap-1"
                                          id={Product[0]}
                                          value={Product[0]}
                                          onChange={modelsOnClick}
                                        />
                                        {Product[1]}
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                              </details>
                            )}
                          </li>
                        )
                      )}
                    </ul>
                  </details>
                </li>
              </ul>
            </div>

            {/* Apple Store List */}
            <ul className="menu menu-horizontal px-1 z-30">
              <li>
                <details>
                  <summary>店舖</summary>
                  <ul className=" w-max bg-gray-900">
                    {Object.entries(AppleStores.stores).map((store) => (
                      <li className="py-1">
                        <button
                          className="btn btn-ghost btn-sm flex justify-start"
                          value={store[0]}
                          onClick={storesOnClick}
                        >
                          <input
                            type="checkbox"
                            class="checkbox checkbox-secondary checkbox-xs p-0 gap-1"
                            value={store[0]}
                            id={store[0]}
                            onChange={storesOnClick}
                          />
                          {store[1]}
                        </button>
                      </li>
                    ))}
                  </ul>
                </details>
              </li>
            </ul>

            {/* 更新間距 */}

            {/* Refresh Button */}

            {modifyBtn ? (
              <button
                className={
                  "btn-sm btn-active btn-error whitespace-nowrap ring-2 ring-blue-500 "
                }
                onClick={modifyBtnClick}
              >
                <span>更新</span>
              </button>
            ) : null}
          </div>
        </div>
        <div className=" navbar-end pr-5">
          {!mobileMode ? (
            <button className="btn btn-square" onClick={displayModeBtnClicked}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
                />
              </svg>
            </button>
          ) : (
            <button className="btn btn-square" onClick={displayModeBtnClicked}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
