import React, { useContext } from "react";
import { SavedCounter } from "../utils/SavedCounterContext";
function Counter() {
  const count = useContext(SavedCounter);
  if (count.countValue.countModel > 0) {
    return (
      <div className="counter p-5">
        <div className="stats stats-vertical bg-neutral shadow md:stats-horizontal">
          <div className="stat">
            <div className="stat-figure text-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-8 h-8 stroke-current stroke-2"
              >
                <circle cx="12" cy="12" r="10" />{" "}
                <line x1="22" y1="12" x2="18" y2="12" />{" "}
                <line x1="6" y1="12" x2="2" y2="12" />{" "}
                <line x1="12" y1="6" x2="12" y2="2" />{" "}
                <line x1="12" y1="22" x2="12" y2="18" />
              </svg>
            </div>
            <div className="stat-title">已監察產品數量</div>

            <div className="stat-value text-primary py-2">
              {count.countValue.countModel}/20
            </div>
          </div>

          <div className="stat">
            <div className="stat-figure text-secondary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-8 h-8 stroke-current stroke-2"
              >
                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                <polyline points="5 12 3 12 12 3 21 12 19 12" />{" "}
                <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />{" "}
                <rect x="10" y="12" width="4" height="4" />
              </svg>
            </div>
            <div className="stat-title">已監察店舖數量</div>
            <div className="stat-value text-secondary">
              {count.countValue.countStore}
            </div>
          </div>
          <div className="stat">
            <div className="stat-figure text-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-8 h-8 stroke-current stroke-2"
              >
                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                <circle cx="12" cy="13" r="7" />{" "}
                <polyline points="12 10 12 13 14 13" />{" "}
                <line x1="7" y1="4" x2="4.25" y2="6" />{" "}
                <line x1="17" y1="4" x2="19.75" y2="6" />
              </svg>
            </div>
            <div className="stat-title">資料更新間距</div>
            <div className="stat-value text-primary">{5} 秒</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Counter;
