import appInfo from "../../package.json";

const storage = window.localStorage;

//Check Saved Models Exist
if (storage.getItem("savedModels") === null) {
  storage.setItem("savedModels", JSON.stringify([]));
}

// Check Saved Apple Store Exist
if (storage.getItem("savedAppleStore") === null) {
  storage.setItem("savedAppleStore", JSON.stringify([]));
}

// Check App Version Exist
if (
  storage.getItem("appVersion") === null ||
  storage.getItem("appVersion") !== appInfo.version
) {
  storage.setItem("appVersion", appInfo.version);
}

if (
  storage.getItem("iPhone15Check") === null ||
  storage.getItem("iPhone15Check") !== "true"
) {
  storage.setItem("savedModels", JSON.stringify([]));
  storage.setItem("iPhone15Check", true);
}

if (storage.getItem("mobileMode") === null) {
  storage.setItem("mobileMode", "false");
}

const savedModels = JSON.parse(storage.getItem("savedModels"));
const savedAppleStore = JSON.parse(storage.getItem("savedAppleStore"));
const appVersion = storage.getItem("appVersion");
const mobileMode = storage.getItem("mobileMode");

export default {
  storage,
  savedAppleStore,
  savedModels,
  appVersion,
  mobileMode,
};
