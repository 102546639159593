import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import AppleStores from "../utils/AppleStores";
import LocalStorageController from "../utils/LocalStorageController";
import ProductModels from "../utils/ProductModels";
import { SavedCounter } from "../utils/SavedCounterContext";

function MainPage() {
  const modelList = LocalStorageController.savedModels;
  const storeList = LocalStorageController.savedAppleStore;
  const { countValue, setCountValue } = useContext(SavedCounter);

  const [result, setResult] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [timeout, setTimeout] = useState(5000);
  const [corsApi, setCorsApi] = useState("https://apple.lwp.workers.dev/?");
  const appleCheckApi =
    "https://www.apple.com/hk-zh/shop/fulfillment-messages?pl=true&location=central";

  let partsListLink = "";
  Object.entries(modelList)
    .slice(0, 20)
    .map((item, i) => {
      partsListLink += `&parts.${i}=${item[1]}`;
    });

  useEffect(() => {
    if (modelList.length > 20) {
      document.getElementById("product_overflow_dialog").show();
    }

    if (modelList.length > 0) {
      if (countValue.headerModify) {
        setIsLoading(true);
        axios.get(corsApi + appleCheckApi + partsListLink).then((res) => {
          setResult(res.data.body.content);
          setIsLoading(false);
        });
      }
      setIsLoading(true);
      axios.get(corsApi + appleCheckApi + partsListLink).then((res) => {
        setResult(res.data.body.content);
        setIsLoading(false);
      });

      const interval = setInterval(() => {
        axios.get(corsApi + appleCheckApi + partsListLink).then((res) => {
          setResult(res.data.body.content);
        });
      }, timeout);

      return () => clearInterval(interval);
    }
  }, [partsListLink]);

  function LoadingIcon(props) {
    return (
      <span className="loading loading-infinity loading-xs text-accent"></span>
    );
  }

  if (countValue.countModel === 0) {
    return (
      <div className="mainpage">
        <div className=" w-screen h-screen flex-1">
          <div
            className="hero min-h-screen"
            style={{
              backgroundImage:
                "url(https://beiz.jp/images_P/japanese-style/japanese-style_00015.jpg)",
            }}
          >
            <div className="hero-overlay bg-opacity-60"></div>
            <div className="hero-content text-center text-neutral-content">
              <div className="max-w-md">
                <h1 className="mb-5 text-5xl font-bold">Check 吓有冇貨?</h1>
                <p className="mb-5">想知道依家有冇貨? 不用再打電話去問了!</p>
                <p className="mb-5">
                  請喺上邊先選擇產品及店舖，然後按"更新"按鈕。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!countValue.headerModify) {
    return (
      <div className="mainpage">
        <div className="h-full p-5">
          <div className="overflow-auto rounded-lg shadow">
            <table class="w-full text-sm text-center text-gray-500 dark:text-gray-400 ">
              <caption class="caption-top text-left py-1">
                最後更新: {new Date().toTimeString()}
              </caption>
              <thead className="text-md text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th className="font-semibold tracking-wide">產品</th>

                  {Object.entries(storeList).map((store) => {
                    return (
                      <th>
                        {AppleStores.stores[store[1]]}
                        <small>{" (" + store[1] + ")"}</small>
                      </th>
                    );
                  })}
                  <th>送貨</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(modelList)
                  .slice(0, 20)
                  .map((model) => {
                    let productName = null;

                    for (const productCat in ProductModels.Products) {
                      if (
                        ProductModels.Products[productCat].hasOwnProperty(
                          model[1]
                        )
                      ) {
                        productName =
                          ProductModels.Products[productCat][model[1]];
                        break;
                      }
                    }
                    return (
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th
                          scope="row"
                          class="px-2 py-2 text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {productName}
                        </th>

                        {Object.entries(storeList).map((store) => {
                          return (
                            <td>
                              {isLoading ? (
                                <LoadingIcon />
                              ) : (
                                result?.pickupMessage.stores
                                  .filter((fStore) => {
                                    return fStore?.storeNumber === store[1];
                                  })
                                  .map((item) => {
                                    if (
                                      item.partsAvailability[model[1]]
                                        .pickupDisplay === "unavailable" ||
                                      item.partsAvailability[model[1]]
                                        .pickupDisplay === "ineligible" ||
                                      item.partsAvailability[model[1]]
                                        .storePickEligible === false
                                    ) {
                                      return (
                                        <div className="badge badge-error gap-2 truncate cursor-not-allowed text-error-content">
                                          {countValue.mobileMode === true ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="currentColor"
                                              class="w-6 h-6"
                                            >
                                              <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                              />
                                            </svg>
                                          ) : (
                                            item.partsAvailability[model[1]]
                                              .pickupSearchQuote
                                          )}
                                        </div>
                                      );
                                    } else if (
                                      item.partsAvailability[model[1]]
                                        .pickupDisplay === "default"
                                    ) {
                                      return (
                                        <div className="badge badge-warning gap-2 truncate cursor-not-allowed text-error-content">
                                          {countValue.mobileMode === true ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="currentColor"
                                              class="w-6 h-6"
                                            >
                                              <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                              />
                                            </svg>
                                          ) : (
                                            item.partsAvailability[model[1]]
                                              .pickupSearchQuote
                                          )}
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div className="badge badge-success gap-2 truncate text-white font-bold">
                                          <a
                                            href={`https://store.apple.com/hk-zh/product/${model[1]}`}
                                            target="_blank"
                                          >
                                            {countValue.mobileMode === true? (
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                class="w-6 h-6"
                                              >
                                                <path
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  d="M4.5 12.75l6 6 9-13.5"
                                                />
                                              </svg>
                                            ) : (
                                              item.partsAvailability[model[1]]
                                                .pickupSearchQuote
                                            )}
                                          </a>
                                        </div>
                                      );
                                    }
                                  })
                              )}
                            </td>
                          );
                        })}

                        <td>
                          {isLoading ? (
                            <LoadingIcon />
                          ) : result?.deliveryMessage[model[1]].regular
                              .isBuyable ? (
                            <div className="badge badge-success gap-2 truncate text-success-content font-bold">
                              <a
                                href={`https://store.apple.com/hk-zh/product/${model[1]}`}
                                target="_blank"
                              >
                                {countValue.mobileMode === true
                                  ? result?.deliveryMessage[model[1]].regular
                                      .deliveryOptions[0].date
                                  : result?.deliveryMessage[model[1]].regular
                                      .deliveryOptionMessages[0].displayName}
                              </a>
                            </div>
                          ) : (
                            <div className="badge badge-error gap-2 truncate cursor-not-allowed text-error-content">
                              {countValue.mobileMode === true  ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-6 h-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg>
                              ) : (
                                result?.deliveryMessage[model[1]].regular
                                  .deliveryOptionMessages[0].displayName
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen p-5">
      <div className="alert alert-info w-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          className="stroke-current shrink-0 w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
        <span>
          暫停更新，請先選好產品及店舖後，再按 "更新" 來重新整理表格。
        </span>
      </div>
    </div>
  );
}

export default MainPage;
