import React, { useState } from "react";
import Counter from "./pages/Counter";
import Header from "./pages/Header";
import "./stylesheets/App.css";
import { SavedCounter } from "./utils/SavedCounterContext";
import LocalStorageController from "./utils/LocalStorageController";
import MainPage from "./pages/MainPage";

function App() {
  const initialCountValue = {
    countModel: LocalStorageController.savedModels.length,
    countStore: LocalStorageController.savedAppleStore.length,
    mobileMode: LocalStorageController.mobileMode,
    headerModify: false,
  };

  const [countValue, setCountValue] = useState(initialCountValue);

  return (
    <div className="App">
      {" "}
      <SavedCounter.Provider value={{ countValue, setCountValue }}>
        <header className="z-50">
          <Header />
        </header>
        <section className="bg-base-800 z-10">
          <Counter />
        </section>
        <section className="bg-base-800 z-10">
          <MainPage />
        </section>
      </SavedCounter.Provider>
    </div>
  );
}

export default App;
