const Products = {
  "iPhone 15": {
    "MTLG3ZA/A": "15 128藍",
    "MTLM3ZA/A": "15 256藍",
    "MTLT3ZA/A": "15 512藍",
    "MTLE3ZA/A": "15 128粉紅",
    "MTLK3ZA/A": "15 256粉紅",
    "MTLQ3ZA/A": "15 512粉紅",
    "MTLF3ZA/A": "15 128黃",
    "MTLL3ZA/A": "15 256黃",
    "MTLR3ZA/A": "15 512黃",
    "MTLH3ZA/A": "15 128綠",
    "MTLN3ZA/A": "15 256綠",
    "MTLU3ZA/A": "15 512綠",
    "MTLD3ZA/A": "15 128黑",
    "MTLJ3ZA/A": "15 256黑",
    "MTLP3ZA/A": "15 512黑",
  },

  "iPhone 15 Plus": {
    "MTXD3ZA/A": "15 Plus 128藍",
    "MTXJ3ZA/A": "15 Plus 256藍",
    "MTXP3ZA/A": "15 Plus 512藍",
    "MTXA3ZA/A": "15 Plus 128粉紅",
    "MTXG3ZA/A": "15 Plus 256粉紅",
    "MTXM3ZA/A": "15 Plus 512粉紅",
    "MTXC3ZA/A": "15 Plus 128黃",
    "MTXH3ZA/A": "15 Plus 256黃",
    "MTXN3ZA/A": "15 Plus 512黃",
    "MTXE3ZA/A": "15 Plus 128綠",
    "MTXK3ZA/A": "15 Plus 256綠",
    "MTXQ3ZA/A": "15 Plus 512綠",
    "MTX93ZA/A": "15 Plus 128黑",
    "MTXF3ZA/A": "15 Plus 256黑",
    "MTXL3ZA/A": "15 Plus 512黑",
  },

  "iPhone 15 Pro": {
    "MTQ63ZA/A": "15P 128原",
    "MTQA3ZA/A": "15P 256原",
    "MTQF3ZA/A": "15P 512原",
    "MTQK3ZA/A": "15P 1T原",
    "MTQ73ZA/A": "15P 128藍",
    "MTQC3ZA/A": "15P 256藍",
    "MTQG3ZA/A": "15P 512藍",
    "MTQL3ZA/A": "15P 1T藍",
    "MTQ53ZA/A": "15P 128白",
    "MTQ93ZA/A": "15P 256白",
    "MTQE3ZA/A": "15P 512白",
    "MTQJ3ZA/A": "15P 1T白",
    "MTQ43ZA/A": "15P 128黑",
    "MTQ83ZA/A": "15P 256黑",
    "MTQD3ZA/A": "15P 512黑",
    "MTQH3ZA/A": "15P 1T黑",
  },
  "iPhone 15 Pro Max": {
    "MU2Q3ZA/A": "15P Max 256原",
    "MU2V3ZA/A": "15P Max 512原",
    "MU603ZA/A": "15P Max 1T原",
    "MU2R3ZA/A": "15P Max 256藍",
    "MU2W3ZA/A": "15P Max 512藍",
    "MU613ZA/A": "15P Max 1T藍",
    "MU2P3ZA/A": "15P Max 256白",
    "MU2U3ZA/A": "15P Max 512白",
    "MU2Y3ZA/A": "15P Max 1T白",
    "MU2N3ZA/A": "15P Max 256黑",
    "MU2T3ZA/A": "15P Max 512黑",
    "MU2X3ZA/A": "15P Max 1T黑",
  },

  AirPods: {
    "MTJV3ZP/A": "Airpods Pro 2 USB-C",
  },

  // "14 Pro": {
  //   "MQ0D3ZA/A": "14P 128紫",
  //   "MQ1C3ZA/A": "14P 256紫",
  //   "MQ263ZA/A": "14P 512紫",
  //   "MQ2Y3ZA/A": "14P 1T紫",
  //   "MQ053ZA/A": "14P 128金",
  //   "MQ143ZA/A": "14P 256金",
  //   "MQ203ZA/A": "14P 512金",
  //   "MQ2R3ZA/A": "14P 1T金",
  //   "MPXY3ZA/A": "14P 128銀",
  //   "MQ0W3ZA/A": "14P 256銀",
  //   "MQ1R3ZA/A": "14P 512銀",
  //   "MQ2K3ZA/A": "14P 1T銀",
  //   "MPXR3ZA/A": "14P 128黑",
  //   "MQ0M3ZA/A": "14P 256黑",
  //   "MQ1J3ZA/A": "14P 512黑",
  //   "MQ2D3ZA/A": "14P 1T黑",
  // },
  // "14 Pro Max": {
  //   "MQ863ZA/A": "14PM 128紫",
  //   "MQ8A3ZA/A": "14PM 256紫",
  //   "MQ8G3ZA/A": "14PM 512紫",
  //   "MQ8M3ZA/A": "14PM 1T紫",
  //   "MQ853ZA/A": "14PM 128金",
  //   "MQ893ZA/A": "14PM 256金",
  //   "MQ8F3ZA/A": "14PM 512金",
  //   "MQ8L3ZA/A": "14PM 1T金",
  //   "MQ843ZA/A": "14PM 128銀",
  //   "MQ883ZA/A": "14PM 256銀",
  //   "MQ8E3ZA/A": "14PM 512銀",
  //   "MQ8J3ZA/A": "14PM 1T銀",
  //   "MQ833ZA/A": "14PM 128黑",
  //   "MQ873ZA/A": "14PM 256黑",
  //   "MQ8D3ZA/A": "14PM 512黑",
  //   "MQ8H3ZA/A": "14PM 1T黑",
  // },
};

export default { Products };
